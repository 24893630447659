<template>
  <div id="demo-basic-card ">
    <div class="vx-row justify-content-between">
      <div class="vx-col lg:w-1/2 w-full vx-card w-45">
        <div class="mb-base">
          <div class="vx-card__header">
            <div class="vx-card__title"><h4 class="">Access List</h4>
              <!---->
            </div><!---->
          </div>
          <div class="vx-card__collapsible-content vs-con-loading__container">
            <div class="vx-card__body">
              <vs-list v-for="user in activeInvitations" :key="user._id">
                <vs-list-item :title="user.parentDetails.fullName" ><a href="#" @click="removeParent(user.parentDetails._id)">Remove</a></vs-list-item>
              </vs-list>
            </div><!---->
          </div>
          <div class="vx-card__code-container collapsed" style="max-height: 0px; display: none;">
            <div class="code-content">
              <pre class="language-markup"><code class="language-markup"></code></pre>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-col lg:w-1/2 w-full vx-card w-45">
        <div class="mb-base">
          <div class="vx-card__header">
            <div class="vx-card__title"><h4 class="">Invite Pending</h4>
              <!---->
            </div><!---->
          </div>
          <div class="vx-card__collapsible-content vs-con-loading__container">
            <div class="vx-card__body">
              <vs-list v-for="user in pendingInvitations" :key="user._id">
                <vs-list-item :title="user.parentDetails.fullName" ><a href="#" @click="removeParent(user.parentDetails._id)">Remove</a></vs-list-item>
              </vs-list>
            </div><!---->
          </div>
        </div>
      </div>
    </div>

    <div class="vx-row mt-10 button-wrap justify-content-between">
      <div class="vx-col lg:w-1/2 w-full mr-0 w-45">

      </div>
      <div class="vx-col lg:w-1/2 w-full ml-0 w-45">
        <a href="#" class="vs-component vs-button mb-md-0 mb-2 vs-button-primary vs-button-filled" @click="undo">Undo</a>
        <a href="#" class="vs-component vs-button mb-md-0 mb-2 ml-2 vs-button-primary vs-button-filled" @click="submit">Save</a>
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions} from "vuex";
import _ from "lodash";

export default {

  data() {
    return {
      firstName:"",
      lastName:"",
      relationToChild:"",
      email:"",
      childId:null,

      allInvitations:[],
      invitations:[],
      removeIds:[],
    };
  },
  methods: {
    ...mapActions("parent", [
      "removeParentFromChildAccess",
      "getInvitedList"
    ]),
    undo(){
      this.invitations = [].concat(this.allInvitations);
      this.removeIds = [];
    },
    removeParent(userId){
      this.removeIds.push(userId);
      const userIndex = this.invitations.findIndex((u) => u.parentDetails._id == userId)
      this.invitations.splice(userIndex, 1)
    },
    submit(){
      let data = {
        childId : this.childId,
        removeParentIds : this.removeIds,
      }
      this.removeParentFromChildAccess(data)
        .then((res)   => {
            this.$vs.notify({
              title: 'Success',
              text: "Invite removed Successfully",
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success'
            })
          })
        .catch(err => { console.error(err) })

      this.$router.push({ name: 'access-list' });

    },
    getInvitedUserList(){
      this.getInvitedList(this.childId)
        .then((res)   => {
          this.allInvitations = [].concat(res.data.data);
          this.invitations = [].concat(res.data.data);
        })
        .catch(err => { console.error(err) })
    },
  },
  created() {
    this.childId = this.currentChild._id;
    this.getInvitedUserList();
  },
  computed: {
    pendingInvitations: function () {
      return _.filter(this.invitations, function(user){
        return user.parents.hasProfileAccess == false;
      });
    },
    activeInvitations: function () {
      return _.filter(this.invitations, function(user){
        return user.parents.hasProfileAccess == true || user.parents.hasProfileAccess != false ;
      });
    },
    currentChild(){
      return this.$store.state.AppParentSelectedChild;
    }
  },
}
</script>
